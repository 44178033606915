
import React, { useContext, useState } from "react"
import { navigate, graphql } from "gatsby"
import {  getImage } from 'gatsby-plugin-image';
import Seo from "../seo"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { Box } from "@mui/system"

import Prices from "../books/Prices"

import { addToCart } from "../../contexts/actions"
import { cartContext } from "../../contexts"

import { GridContainer, Container } from "../../grid"
import { Zoom } from "@mui/material"

import { Cover } from "../books/Cover";
import LookInsideBtn from "../books/LookInsideBtn";

import DialogiLabel from "./DialogiLabel";




const DialogiSinglePage = ({data, children, hideCoverMobile = false}) => {
  
  // console.log(data)

  const {cart, dispatchCart} = useContext(cartContext)

  const [inCart, setInCart] = useState(
    cart.findIndex( item => item.id === data.strapi_id ) !== -1
  )

  const addCartHandler = ({id, title, author, prices, cover, slug}) => {
    dispatchCart(addToCart({
      id,
      title,
      author,
      prices,
      cover,
      slug,
      type: 'dialogi'
    }, 1))
    setInCart(true)
  }

  const image = getImage(data.cover.localFile)

  return (
    <>
      
      {/* Hero Area */}
      <Box component='section' sx = { (theme) => ({
        backgroundColor: theme.palette.white,
      })}>
        <Container 
          pt={{
            xs: 4,
            md: 4,
            xl: 8
          }}
        >
          <GridContainer>
            
            <Grid item xs={12} xl={4} xxxl={4} sx = { (theme) => ({
              position: {
                xl: 'sticky'
              },
              top: theme.spacing(8),
              left: -theme.spacing(8),
              display: {
                xs: hideCoverMobile ? 'none' : 'block',
                xl: 'block'
              }
            })}>
              

                <Cover 
                  sx = { (theme) => ({
                    width: {xs: '60%', md2: '100%'},
                    maxWidth: {xs: 300, xl: 250, xxl: 350, xxxl: 400},
                    mb: {xs: 6, sm: 7},
                  })} 
                  img={image} 
                  alt={data.title} 
                  gatsbyimageprops={{
                    loading: 'eager'
                  }}
                />
              

              {/* title + desc */}
              <Box>
                <DialogiLabel 
                  {...data.leto}
                  stevilka = {data.stevilka}
                  sx = { (theme) => ({
                    m: 0,
                    mb: theme.spacing(4),
                    fontSize: {
                      xs: '1.5rem',
                      lg: '1.5rem'
                    }
                  })}
                />
                <Typography sx = { (theme) => ({
                  fontSize: {
                    // xl: '1.75rem'
                  }
                })} variant="bookTitle">{data.title}</Typography>
              </Box>
              {/* <Box>
                {data.description && 
                  <Box dangerouslySetInnerHTML={{__html: data.description.html}} />
                }
              </Box> */}
             
        
              <Box sx = { (theme) => ({
                mt: 6,
                width: '100%'
              })}>
                <Box 
                  sx = { (theme) => ({
                    display: 'flex',
                    gap: theme.spacing(4),
                    flexDirection: 'row',
                    alignItems: 'center',
                    '&>a, &>div' : {
                      // flex: '0 0 0px',
                    }
                  })}
                >
                  {/* <Button 
                    sx = { (theme) => ({
                      width: {xs: '100%', sm: 'auto'},
                    })} 
                    variant='contained'
                    component={Link}
                    to='/dialogi/postani-narocnik'
                  >
                    Postani naročnik
                  </Button> */}
                  
                  <Prices sx={{fontSize: '1.25rem'}} data={data.prices} />

                  <Box sx = { (theme) => ({
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center',
                    ml:{
                      xs: 2,
                      md: 4
                    }
                  })}>
                    <Button 
                      sx = { (theme) => ({
                        width: {xs: '100%', sm: 'auto'},
                        backgroundColor: inCart ? theme.palette.primary.main : 'transparent',
                        color: inCart ? theme.palette.white : theme.palette.primary.main
                      })} 
                      variant='outlined'
                      disabled={data.leto.year < 2018}
                      onClick={()=>{
                        if (inCart) {
                          navigate('/kosarica')
                        } else {
                          addCartHandler({
                            id: data.strapi_id,
                            title: data.title,
                            prices: data.prices,
                            cover: data.cover.localFile,
                            author: `Dialogi ${data.stevilka}-${data.leto.year}`,
                            slug: `/dialogi/${data.leto.year}/${data.stevilka}`
                          })
                        }
                      }}
                      > 
                        { inCart ? 'Zaključi nakup' : 'Dodaj v košarico' }
                      </Button>
                      <Zoom in={inCart}>
                        <Box 
                          component={'span'}
                          sx = {{
                            fontSize: '0.75em',
                            lineHeight: '2em',
                            marginBottom: '-2em'
                          }}
                        >
                          Knjiga je v košarici
                        </Box>
                      </Zoom>
                  </Box>
                  
                  
                </Box>
              </Box>

              {data.pdf &&
                <Box mt={6}>
                  <LookInsideBtn file={data.pdf.url}>Prelistaj številko (PDF)</LookInsideBtn>
                </Box>
              }

            </Grid>

            {/* Content */}
            <Grid item xs={12} xl={8} xxxl={8} sx = { (theme) => ({
              mt: {xs: 11, xl: 0}
            })}>
              {children}
            </Grid>

          </GridContainer>  
        </Container>
      </Box>
    </>
  )
}

export default DialogiSinglePage

