import React, { useContext, useState } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Box } from "@mui/system"
import * as styles from './../styles/article.module.scss'
import DialogiSinglePage from "../components/dialogi/DialogiSinglePage"

  
 const ArticleSingle = ({ pageContext, data: rowData }) => {
  
  const data = pageContext.data
  const dialogiData = rowData.dialogi

  // console.log(data)
  return (
    <>
      <Seo title={`Dialogi ${data.dialogi.stevilka} ${data.dialogi.leto.year} | ${data.subtitle}`} />
      <DialogiSinglePage data={dialogiData} hideCoverMobile={true}>
        <Box className={styles.content} pt={8}>
          <h3 className={styles.subtitle}>{`– ${data.subtitle} –`}</h3>
          <h1 className={styles.title}>{data.title}</h1>
          <h3 className={styles.author}>{data.author}</h3>
          <Box dangerouslySetInnerHTML={{__html: data.content.data.content}} />
        </Box>
      </DialogiSinglePage>
    </>
  )
}

export default ArticleSingle

export const query = graphql`
  query($id: Int){
    dialogi: strapiDialogi(strapi_id: {eq: $id}) {
      strapi_id
      id
      stevilka
      title
      leto {
        color
        year
        number
        textColor
      }
      description: childStrapiDialogiDescriptionTextnode {
        html: description
      }
      kazalo {
        data {
          kazalo
        }
      }
      prices {
        regular
        online
        discount
      }
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
      }
      pdf {
        url
      }
    }
  }
`