import React from 'react';
import { SvgIcon, Button } from '@mui/material';
import { styled } from '@mui/material/styles';


export default function LookInsideBtn({children, file}) {

  const ContentSvg = styled(SvgIcon)(
    ({ theme }) => `
      & path, & circle {
        stroke: ${theme.palette.primary.main};
        stroke-width: 2px;
        stroke-linecap: round;
        fill: none;
      }
    `,
  );

  const Icon = (props) => (
    <ContentSvg {...props}>
      <path d="M4 4.5h16M4 9.5h10M4 14.5h16M4 19.5h10"/>
    </ContentSvg>
  )

  return (
   <Button 
      href={`${process.env.GATSBY_STRAPI_API_URL}${file}`} 
      component="a" 
      startIcon={<Icon fontSize="small" />}
      target={'__blank'}
    >{children}</Button>
  )
};